<!--
 * @Author: DQL
 * @Date: 2020-03-09 17:00:21
 * @LastEditors: DQL
 * @LastEditTime: 2023-02-26 11:27:05
 * @Description: 微信扫码绑定用户
 -->
<template>
  <div>
    <div
      class="o-loginT-tips"
    >
      首次扫码，需先绑定系统用户
    </div>
    <el-form
      ref="fd"
      class="c-login__form"
      :model="fd"
      :rules="fdRules"
      size="normal"
      label-width="0px"
    >
      <el-form-item
        prop="sfzjhm"
      >
        <el-input
          v-model.trim="fd.sfzjhm"
          type="text"
          prefix-icon="el-icon-postcard"
          placeholder="请输入身份证件号码"
        />
      </el-form-item>
      <el-form-item
        prop="sjhm"
      >
        <el-input
          v-model="fd.sjhm"
          type="text"
          prefix-icon="el-icon-phone"
          placeholder="请输入手机号码"
        />
      </el-form-item>
      <el-form-item
        class="o-login-bar"
      >
        <el-button
          size="normal"
          type="primary"
          style="width: 100%;"
          :loading="logining"
          @click.native.prevent="handleSubmitBind"
        >
          <span class="u-mg-l-12">绑定</span>
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { wxBindUser } from '@/features/feature.common.service';

export default {
  name: 'WxScanBind',
  props: {
    openid: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      logining: false,

      /* 表单参数 Start */
      fd: {
        sfzjhm: '',
        sjhm: '',
        openid: ''
      },
      /* 表单参数 End */

      /* 表单校验规则 Start */
      fdRules: {
        sfzjhm: [
          { required: true, message: '请输入身份证号', trigger: 'blur' }
        ],
        sjhm: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }
        ]
      },
      /* 表单校验规则 End */
      loginError: false
    };
  },
  computed: {
  },
  methods: {
    handleSubmitBind () {
      const _this = this;
      _this.fd.openid = _this.openid;
      wxBindUser(_this.fd)
        .then(resp => {
          if (resp.data.data) {
            // 绑定成功，自动登录
            _this.loading = false;
            _this.$emit('callback');
          }
        }).catch(err => {
          console.error('绑定用户失败！', err);
          _this.loading = false;
        });
    }

  },
  watch: {
    loginType: function (value) {
      console.log(value);
      if (value && value === '9') {
        this.handleRefreshQrcode();
      }
    }
  },
  created () {
  }
};

</script>

<style lang="scss">
@import './login.scss';
</style>
