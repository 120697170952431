<!--
 * @Author: zxf
 * @Date: 2023-02-09 15:30:36
 * @LastEditors: cxb chenxiaobiao@hongmeng-info.com
 * @LastEditTime: 2023-03-25 11:19:08
 * @Description: file content
-->
<template>
  <div class="o-face-recognize__body">
    <div>
      <p
        class="o-camera-tips"
        :class="{'o-camera-tips--error': reOpenCameraFlag}"
      >
        {{ facetip }}
      </p>

      <p
        v-if="reOpenCameraFlag == true"
        style="font-size: 16px;color: cornflowerblue;"
        @click="reOpenCamera"
      >
        重新识别
      </p>
      <div
        class="o-video-body"
      >
        <video
          id="video"
          class="o-face-video"
          autoplay
        />
        <canvas
          id="canvas"
          width="277"
          height="277"
          class="o-face-video"
        />
      </div>
    </div>
  </div>
</template>
<script>

import { userMedia } from '@/assets/js/utils.js';

require('tracking/build/tracking-min.js');
require('tracking/build/data/face-min.js');
export default {
  name: 'FaceRecognize',
  props: {
    warningText: {
      type: String,
      default: '请确保正脸出现在取景框中，以便识别'
    },
    photoLength: {
      type: Number,
      default: 1
    },
    faceError: {
      type: Boolean,
      default: false
    },
    inputActionCode: {
      type: String,
      default: ''
    },
    openMp4: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      reOpenCameraFlag: false,
      videoObj: null,
      photos: [],
      timer: null,
      recordBase64: null,
      mediaRecorder: null,
      startRecordFlag: false,
      actionCodeStr: '9',
      endFlag: false,
      facetip: '请打开摄像头进行人脸识别',
      actionCode: {
        9: '请确保正脸出现在取景框中，以便识别',
        0: '请眨眼',
        4: '请抬头',
        5: '请低头',
        7: '请左右转头'
      }
    };
  },
  methods: {
    reOpenCamera () {
      this.reOpenCameraFlag = false;
      // 重新打开摄像头事件通知
      this.$emit('reOpen', '');
      this.openCamera();
    },
    /**
     * 打开摄像头
     */
    openCamera () {
      // 有可能触发一些其他的按钮会重新获取
      this.$nextTick(() => {
        const constraints = {
          video: {
            width: 120,
            height: 120,
            frameRate: {
              ideal: 10,
              max: 15
            },
            facingMode: 'environment'
          },
          audio: false
        };

        userMedia(constraints, this.success, this.error);
        this.recordBase64 = null;
        this.mediaRecorder = null;
        this.startRecordFlag = false;
        this.endFlag = false;
        this.facetip = this.warningText;
        // 重置识别到的图片
        this.photos = [];

        const canvas = document.getElementById('canvas');
        this.videoObj = document.getElementById('video');
        if (!this.videoObj) {
          return;
        }
        this.timerBuilder();
        // eslint-disable-next-line no-undef
        const tracker = new tracking.ObjectTracker('face'); // 检测人脸
        tracker.setInitialScale(4);
        tracker.setStepSize(2);
        tracker.setEdgesDensity(0.1);
        // eslint-disable-next-line no-undef
        this.trackerTask = tracking.track('#video', tracker, { camera: true });
        const _this = this;
        const context = canvas.getContext('2d');

        this.facetip = '请确保正脸出现在取景框中，以便识别';

        tracker.on('track', (event) => {
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.drawImage(this.videoObj, 0, 0, canvas.width, canvas.height);
          event.data.forEach((rect) => {
          // 绘制到 canvas
            context.strokeStyle = '#1890ff';
            context.strokeRect(rect.x, rect.y, rect.width, rect.height);
          });
          if (event.data) {
            if (_this.endFlag) {
              return;
            }

            setTimeout(() => { _this.handleGetImage(event.data, canvas); }, 1000);
            if (_this.openMp4 && !_this.startRecordFlag) {
              _this.startRecordFlag = true;
              setTimeout(() => { _this.handleMp4(canvas, _this); }, 200);
              if (_this.inputActionCode) {
                _this.actionCodeStr = _this.inputActionCode;
              }
              setTimeout(() => { _this.handlerAction(_this, 0); }, 200);
            }
          }
        });
      });
    },
    /**
     * 设置Action动作
     */
    handlerAction (_this, _actionNum) {
      // 当前动作信息
      const actionPoit = _this.actionCodeStr.charAt(_actionNum);
      const actioin = _this.actionCode[actionPoit];
      _this.facetip = actioin;
      // 执行下一个动作
      if (_actionNum < _this.actionCodeStr.length - 1) {
        setTimeout(() => {
          _this.handlerAction(_this, _actionNum + 1);
        }, 3000);
      } else {
        setTimeout(() => {
          _this.facetip = '正在识别，请稍后';
          _this.handlerCloseMediaRecorder();
        }, 3000);
      }
    },
    /**
     * 录像设置
     */
    handleMp4 (canvas, _this) {
      var stream = canvas.captureStream(60);
      var recordedChunks = [];
      _this.mediaRecorder = new MediaRecorder(stream, {
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 1000000,
        mimeType: 'video/webm'
      });

      _this.mediaRecorder.start(0);

      _this.mediaRecorder.ondataavailable = function (e) {
        recordedChunks.push(e.data);
      };
      _this.mediaRecorder.onstop = function (event) {
        var file = new File(recordedChunks, 'c.mp4',
          { type: 'video/mp4' }
        );
        // var blob = new Blob(recordedChunks, {
        //   type: 'video/mp4'
        // });
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          _this.recordBase64 = (e.target.result).replace('data:video/mp4;base64,', '');
          console.log('返回视频信息：', { images: _this.photos, video: _this.recordBase64 });
          _this.$emit('callback', { images: _this.photos, video: _this.recordBase64 });
        };
      };
    },
    /**
     * 关闭录像
     */
    handlerCloseMediaRecorder () {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
      }
    },
    /**
     * 完成后回调
     */
    handlerCallBack () {
      if (this.openMp4) {
        // 使用了MP4,不通过这里返回数据
        return;
      }
      if (this.photos.length < this.photoLength) {
        // 当前图片数量不满足
        return;
      }
      if (this.endFlag) {
        // 已经完成，不再发送图片
        return;
      }
      this.endFlag = true;
      console.log('返回图片信息：', { images: this.photos, video: this.recordBase64 });
      this.$emit('callback', { images: this.photos, video: this.recordBase64 });
    },
    handleGetImage (data, canvas) {
      if (data.length === 1 && this.photos.length <= this.photoLength) {
        // 说明检测到人脸了，此时就可以截取图片传递给后端
        // canvas 调用 toDataURL
        this.faceWarning = false;
        const imgBase64 = canvas.toDataURL('image/jpeg', 0.7);
        const str = imgBase64.replace('data:image/jpeg;base64,', '');
        this.photos.push(str);
      }
      this.handlerCallBack();
    },

    handleCancel () {
      if (this.videoObj && this.videoObj.srcObject) {
        this.videoObj.srcObject.getTracks()[0].stop();
        this.videoObj = null;
      }
      if (this.trackerTask) {
        this.trackerTask.stop();
      }
    },

    // 成功显示
    success (stream) {
      this.videoObj.srcObject = stream;
      this.videoObj.play();
    },
    // 失败抛出错误，可能用户电脑没有摄像头，或者摄像头权限没有打开
    error (error) {
      // 可以在这里面提示用户
      this.facetip = '识别失败,未能打开摄像头';
      this.reOpenCameraFlag = true;
      clearTimeout(this.timer);
      console.log(`访问用户媒体设备失败${error.name}, ${error.message}`);
    },
    timerBuilder () {
      clearTimeout(this.timer);
      this.timer = null;
      this.timer = setTimeout(() => {
        this.handleCancel();
        this.reOpenCameraFlag = true;
        this.facetip = '识别超时失败';
        this.$emit('callback', { error: '识别超时' });
      }, 30000);
    }
  },
  watch: {
    faceError (value) {
      this.reOpenCameraFlag = value;
      return value;
    },
    warningText (value) {
      this.facetip = value;
      return value;
    }
  },
  unmouted () {
    this.handleCancel();
    clearTimeout(this.timer);
    this.timer = null;
  },
  mounted () {
    this.reOpenCameraFlag = this.faceError;
    this.openCamera();
  },
  destroyed () {

  }
};
</script>
<style lang="scss" scoped>

.o-video-body {
  position: relative;
  width: 287px;
  height: 287px;
  margin: auto;
  text-align: center;
  background-image: url('/assets/sb.png');
  background-repeat: no-repeat;
  background-position: center;
}

.o-video-body-error {
  width: 100%;
  height: 200px;
  text-align: center;
  background-color: #888;
}

.o-face-video{
  position: absolute;
  top: 36px;
  left: 30px;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  // background-color: #5eadeb26;
  // background-image: url('/assets/sbtx.svg');
  background-repeat: no-repeat;
  background-position: center;
  transform: rotateY(180deg);
}

.o-face-recognize__body{
  text-align: center;
}

.o-camera-tips{
  display: inline-block;
  box-sizing: border-box;
  width: 336px;
  padding: 12px 28px;
  margin-top: 18px;
  margin-bottom: 52px;
  border-radius: 22px;
  font-size: 16px;
  color: #046ffb;
  text-align: center;
  background: rgb(243 249 255 / 80%);

  &--error{
    color: #f00;
    background: #fff6f6;
  }
}
</style>
